import { Button, Drawer, Link, Stack, Typography, styled } from "@mui/material";
import { getUseAppLink } from "helpers/getMarketplaceAppLink";
import { useDeviceDetection } from "hooks/useDeviceDetection";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";

type DownloadAppModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DownloadAppModal = ({ isOpen, onClose }: DownloadAppModalProps) => {
  const { t } = useTranslation("misc");
  const { isAndroidDevice } = useDeviceDetection();
  const organization = useSelector(selectOrganization);

  const ref = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (organization.disableObBooking && organization.appLinkOrDownload) {
      // ref.current?.click();
      const atag = document.getElementById("downloadApp");
      atag?.click();
    }
  }, [organization.disableObBooking, organization.appLinkOrDownload]);

  const appLink = useMemo(
    () => organization.appLink || getUseAppLink(isAndroidDevice),
    [isAndroidDevice, organization.appLink]
  );

  if (organization.disableObBooking && organization.appLinkOrDownload)
    return (
      <a
        id="downloadApp"
        href={organization.appLinkOrDownload || ""}
        ref={ref}
        style={{ display: "none" }}
      >
        Download app
      </a>
    );

  return (
    <Drawer
      open={isOpen}
      variant="permanent"
      PaperProps={{
        sx: {
          bgcolor: (theme) => theme.palette.primary.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,

          height: "100svh", // mobile friendly version of 100vh
          width: "100%",

          overflow: "hidden",
        },
      }}
    >
      <Stack flexGrow={1} pt={4} gap={4}>
        <Stack direction="row" gap={1} alignItems="center" justifyContent="center" pt={3}>
          <img
            alt="bookpeep-logo"
            src="/images/BookpeepAppIcon.svg"
            style={{
              width: "48px",
              height: "48px",

              filter: "drop-shadow(0px 5.05263px 10.1053px rgba(0, 0, 0, 0.16))",
            }}
          />

          <img alt="bookpeep-logo-text" src="/images/BookpeepLogo.svg" />
        </Stack>

        <Stack alignItems="center" gap={0.5}>
          <Typography variant="h1" color="common.white" fontFamily="Epilogue">
            {t("bookFasterOnApp")}
          </Typography>

          <Typography variant="primary" color="common.white" fontSize="1rem" fontWeight={500}>
            {t("loginOnlyOnce")}
          </Typography>
        </Stack>

        <Stack direction="row" gap={0.5} alignItems="center" justifyContent="center">
          <Link href={appLink} underline="none">
            <img
              alt="download-on-app-store"
              src="/images/DownloadAppOnAppStore.svg"
              height="42px"
            />
          </Link>

          <Link href={appLink} underline="none">
            <img
              alt="download-on-google-play"
              src="/images/DownloadAppOnGooglePlay.svg"
              height="42px"
            />
          </Link>
        </Stack>

        <Stack gap={2} pt={3} alignItems="center" justifyContent="center">
          <ModalButton LinkComponent={Link} href={appLink} fullWidth>
            {t("useApp")}
          </ModalButton>

          <Button
            variant="text"
            sx={{
              color: (theme) => theme.palette.common.white,
              fontWeight: "bold",
            }}
            onClick={onClose}
          >
            {t("continueOnWeb")}
          </Button>
        </Stack>
      </Stack>

      <object type="image/svg+xml" data="/images/DownloadAppModalPhoneWithCircle.svg">
        svg-image
      </object>
    </Drawer>
  );
};

export default DownloadAppModal;

const ModalButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,

  fontWeight: "bold",
  borderRadius: "20px",

  "&:hover": {
    backgroundColor: theme.palette.common.white,
  },
}));
