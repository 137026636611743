import { Container } from "@mui/material";
import DownloadAppModal from "components/DownloadAppModal";
import FooterLayout from "layouts/FooterLayout";
import TopBarLayout from "layouts/TopBarLayout";
import AppointmentPage from "pages/AppointmentPage";
import { AppointmentPageOption } from "pages/AppointmentPage/AppointmentPage";
import BookingPage from "pages/BookingPage";
import CheckoutPage from "pages/CheckoutPage";
import EditUserDetailsPage from "pages/EditUserDetailsPage";
import EmployeeProfilePage from "pages/EmployeeProfilePage";
import ForceDownloadApp from "pages/ForceDownloadApp";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import LoginPage from "pages/LoginPage";
import OutletPage from "pages/OutletPage";
import { OutletBookingContextProvider } from "pages/OutletPage/outletContext";
import OutletPageSkeleton from "pages/OutletPage/OutletPage.skeleton";
import CheckoutSubscriptions from "pages/OutletPage/Subscriptions/CheckoutSubscriptions";
import ReviewSubscriptionPage from "pages/OutletPage/Subscriptions/ReviewSubscriptionPage";
import SubscriptionConfirmation from "pages/OutletPage/Subscriptions/SubscriptionConfirmation";
import ProfileResetPasswordPage from "pages/ProfileResetPasswordPage";
import RegisterPage from "pages/RegisterPage";
import ResetPassword from "pages/ResetPassword";
import ReviewAppointmentPage from "pages/ReviewAppointmentPage";
import UserAppointmentsPage from "pages/UserAppointmentsPage";
import UserProfilePage from "pages/UserProfilePage";
import UserSubscriptionsPage from "pages/UserSubscriptionsPage";
import VerifyPhonePage from "pages/VerifyPhonePage";
import WalletPage from "pages/WalletPage";
import { Suspense, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useMatch } from "react-router-dom";
import { selectOrganization } from "store/selectors";

import AuthenticationRedirector from "./AuthenticationRedirector";
import OutletRedirector from "./OutletRedirector";

export default function AppRoutes() {
  const { pathname } = useLocation();
  const match = useMatch("/:outletSlug");
  const isOnOutletPage = match?.pattern.path === "/:outletSlug" || pathname === "/";
  const shouldHideDownloadAppPage = Boolean(process.env.REACT_APP_HIDE_DOWNLOAD_APP);

  const organization = useSelector(selectOrganization);
  const hasClosedDownloadAppModalOnCurrentSession =
    sessionStorage.getItem("hasClosedDownloadAppModal") === "true";

  const [isDownloadAppModalOpen, setIsDownloadAppModalOpen] = useState(
    organization.isMpVisible &&
      isOnOutletPage &&
      organization.hasActiveSubscription &&
      !hasClosedDownloadAppModalOnCurrentSession &&
      !shouldHideDownloadAppPage
  );

  const handleDownloadAppModalClose = () => {
    setIsDownloadAppModalOpen(false);
    sessionStorage.setItem("hasClosedDownloadAppModal", "true");
  };

  if (pathname.includes("/download")) {
    return <ForceDownloadApp />;
  }

  if (isDownloadAppModalOpen) {
    return (
      <DownloadAppModal isOpen={isDownloadAppModalOpen} onClose={handleDownloadAppModalClose} />
    );
  }

  return (
    <Container disableGutters maxWidth={false}>
      <Routes>
        <Route element={<TopBarLayout />}>
          <Route element={<FooterLayout />}>
            <Route path="/" element={<OutletRedirector />}>
              <Route
                path="/:outletSlug"
                element={
                  // TODO: either wrap every route element in a Suspense or find a proper way to handle ugly current behavior (search keywords useMutableSource and using react Suspense with redux)
                  <Suspense fallback={<OutletPageSkeleton />}>
                    <OutletPage />
                  </Suspense>
                }
              />

              <Route
                path="/"
                element={
                  // TODO: either wrap every route element in a Suspense or find a proper way to handle ugly current behavior (search keywords useMutableSource and using react Suspense with redux)
                  <Suspense fallback={<OutletPageSkeleton />}>
                    <OutletPage />
                  </Suspense>
                }
              />

              <Route
                path="/:outletSlug/confirmation/:appointmentId"
                element={
                  <AppointmentPage pageType={AppointmentPageOption.AppointmentConfirmation} />
                }
              />

              <Route path="/:outletSlug/book" element={<BookingPage />} />

              <Route path="/:outletSlug/checkout" element={<CheckoutPage />} />

              <Route path="/:outletSlug/appointment-review" element={<ReviewAppointmentPage />} />

              <Route
                path="/:outletSlug/staff/:employeeId"
                element={
                  <OutletBookingContextProvider>
                    <EmployeeProfilePage />
                  </OutletBookingContextProvider>
                }
              />

              <Route
                path="/:outletSlug/subscription-review/:subscriptionId"
                element={<ReviewSubscriptionPage />}
              />

              <Route
                path="/:outletSlug/purchase-checkout/:subscriptionId"
                element={<CheckoutSubscriptions />}
              />

              <Route
                path="/:outletSlug/purchase-confirmation/:subscriptionPurchaseId"
                element={<SubscriptionConfirmation />}
              />

              <Route
                path="/purchase-confirmation/:subscriptionPurchaseId"
                element={<SubscriptionConfirmation />}
              />
            </Route>

            <Route element={<AuthenticationRedirector redirectIf="isNotLoggedIn" />}>
              <Route path="/wallet" element={<WalletPage />} />

              <Route path="/profile" element={<UserProfilePage />} />

              <Route path="/profile/bookings" element={<UserAppointmentsPage />} />

              <Route
                path="/profile/bookings/:appointmentId"
                element={<AppointmentPage pageType={AppointmentPageOption.AppointmentDetails} />}
              />

              <Route path="/profile/subscriptions/" element={<UserSubscriptionsPage />} />

              <Route path="/profile/edit" element={<EditUserDetailsPage />} />

              <Route path="/profile/reset-password" element={<ProfileResetPasswordPage />} />

              <Route path="/verify-phone-number" element={<VerifyPhonePage />} />
            </Route>

            <Route element={<AuthenticationRedirector redirectIf="isLoggedIn" />}>
              <Route path="/login" element={<LoginPage />} />

              <Route path="/register" element={<RegisterPage />} />

              <Route path="/reset-password/:token" element={<ResetPassword />} />

              <Route path="/forgot-password/*" element={<ForgotPasswordPage />} />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Container>
  );
}
