import { Close } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";
import { getUseAppLink } from "helpers/getMarketplaceAppLink";
import { useDeviceDetection } from "hooks/useDeviceDetection";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch } from "react-router-dom";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";

import { CallToActionLink } from "./CallToActionLink";

export default function DownloadAppBanner() {
  const { t } = useTranslation(["misc", "common"]);

  const { isAndroidDevice } = useDeviceDetection();

  const { pathname } = useLocation();
  const match = useMatch("/:outletSlug");

  const organization = useSelector(selectOrganization);

  const [isBannerOpen, setIsBannerOpen] = useState(organization.isMpVisible ?? false);

  const isOnOutletPage = match?.pattern.path === "/:outletSlug" || pathname === "/";

  const shouldShowBanner = isBannerOpen && isOnOutletPage;

  if (!shouldShowBanner) return null;

  return (
    <Box
      width="100%"
      display="flex"
      position="sticky"
      justifyContent="space-between"
      flexDirection="row"
      py={2}
      px={1}
      bgcolor="#444444"
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <IconButton sx={{ padding: "0" }} onClick={() => setIsBannerOpen(false)}>
          <Close sx={{ fill: "white" }} />
        </IconButton>
        <img alt="download-app" src="/images/DownloadAppIcon.svg" />
        <Box color="white">
          {/* <Typography variant="h4">
            {t("misc:nowOnPeep", { orgName: organization.name })}
          </Typography> */}
          <Typography variant="h4">{t("misc:loginOnlyOne")}</Typography>
          <Typography variant="secondary">{t("misc:bookFaster")}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <CallToActionLink
          href={organization.appLink || getUseAppLink(isAndroidDevice)}
          onClick={() => {
            ReactGA.event({ category: "Top", action: "Landing App Download", label: "Landing" });
          }}
          paddingY={0.5}
          paddingX={1.5}
        >
          {t("common:open")}
        </CallToActionLink>
      </Box>
    </Box>
  );
}
