import { Offline as OfflineIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,

    integrations: [
      new BrowserTracing(),

      // If offline, it saves events to the web browser's client-side storage then automatically uploads events when network connectivity is restored.
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/plugin/#offline
      new OfflineIntegration({
        // limit how many events will be locally saved. Defaults to 30.
        maxStoredEvents: 35,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // By default Sentry SDKs normalize any context to a depth of 3, which in the case of sending Redux state you probably will want to increase that
    normalizeDepth: 10,
  });

// Sentry Redux Integration
// WARNING: if there are some data that we store in redux that we should not send to Sentry, we need to add some options to disable sending them to sentry
export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});
